<template>
  <el-select v-model="value" :multiple="true" :size="megaMode ? '' : 'mini'" filterable remote :reserve-keyword="false"
    :remote-method="remoteMethod" placeholder="请输入关键词" @change="codeSelectChane" :disabled="disabled" :loading="loading">
    <el-option v-for="(item, index) in options" :key="index" :value="item.code">
      <span>{{ item.name }}</span><span style="margin: 0 10px">{{ item.code }}</span><span>{{ item.pinyin }}</span>
    </el-option>
  </el-select>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs, watch } from "vue";
import vPinyin from '@/utils/vue-py'
import { cloneDeep } from "lodash"
import axios from "@/axios/globalaxios";

export default defineComponent({
  name: "index",
  props: {
    codeType: {
      type: String,
      default: '0'  //  传 1，2，3 默认查全部
    },
    multiple: {
      type: Boolean,
      default: true // 是否多选 默认多选
    },
    codeList: {
      type: Array,
    },
    megaMode: {
      type: Boolean,
      default: false, // 是否大赛 默认否
    },
    disabled: {
      type: Boolean,
      default: false, // 是否禁用 默认否
    }
  },
  setup(props, ctx) {
    onMounted(() => {
      getCodeList()
    })
    // watch(() => [props.codeList],([newVal]) => {
    //   if (newVal){
    //     data.value = newVal
    //     if(props.megaMode) {
    //       data.options = []
    //       data.value = props.codeList
    //       let codeName = props.codeType === '0'?'all':props.codeType === '1'?'shares': props.codeType === '2' ?'bond':'forward'
    //       let codeList = JSON.parse(localStorage.getItem(codeName))
    //       data.list = codeList.map(v=>{return {code:v.fgsid,name:v.fname,pt:v.pt,vt:v.vt,pinyin:vPinyin.chineseToPinYin(v.fname)}})
    //     }
    //   }
    // })
    watch(() => [props.codeList, props.codeType], ([newVal, newType]) => {
      if (newVal) {
        data.value = newVal
        if (props.megaMode) {
          data.options = []
          data.value = props.codeList
          let codeName = props.codeType === '0' ? 'all' : props.codeType === '1' ? 'shares' : props.codeType === '2' ? 'bond' : 'forward'
          let codeList = JSON.parse(localStorage.getItem(codeName))
          if (codeList && codeList.length > 0) {
            data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

          }
        }
      }
      if (newType) {
        getCodeList()
      }

    })
    let data = reactive({
      options: [],
      value: [],
      list: [],
      searchList: [],
      loading: false,
    })
    const remoteMethod = (val) => {
      if (val !== '') {
        data.loading = true;
        setTimeout(() => {
          data.loading = false;
          console.log("data.list",data.list);
          data.options = data.list.filter(item => {
            return item.code.toUpperCase().indexOf(val.toUpperCase()) > -1 || item.name.indexOf(val) > -1 || item.pinyin.toUpperCase().indexOf(val.toUpperCase()) > -1;
          });
        }, 200);
      } else {
        data.options = [];
      }
    }
    let getCodeList = () => {
      data.value = props.codeList
      let codeName = props.codeType === '0' ? 'all' : props.codeType === '1' ? 'shares' : props.codeType === '2' ? 'bond' :  props.codeType === '3' ?'forward':'all'
      let codeList = []
      if (props.codeType === '5') {
        axios.post('/tradelab/transaction/findStopwatchByFtype', { type: null }).then(res => {
          if (res.data.code === '200') {
            codeList = res.data.data.stopwatches
            if (codeList && codeList.length > 0) {
              data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

            }
          }
        })
      } else if (props.codeType === '4') {
        axios.post('/tradelab/transaction/findStopwatchByFtype', { type: 4 }).then(res => {
          if (res.data.code === '200') {
            codeList = res.data.data.stopwatches
            if (codeList && codeList.length > 0) {
              data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

            }
          }
        })
      } else {
        codeList = JSON.parse(localStorage.getItem(codeName))
        if (codeList && codeList.length > 0) {
          data.list = codeList.map(v => { return { code: v.fgsid, name: v.fname, pt: v.pt, vt: v.vt, pinyin: vPinyin.chineseToPinYin(v.fname) } })

        }
      }
      // data.value = data.list[0].code
    }

    const codeSelectChane = () => {
      let arr = cloneDeep(data.value)
      ctx.emit('codeSelectChane', arr)
    }
    return {
      ...toRefs(data),
      remoteMethod,
      codeSelectChane,
    }
  }
})
</script>

<style scoped>
</style>